import type { SurveyType } from '@app/s/rewards/[surveyType]/page'
import { RoutePrefix } from '.'

const TrialProRoutes = {
  accountSettings: `${RoutePrefix.TRIAL_PROS}/account-settings`,
  admin: `${RoutePrefix.TRIAL_PROS}/admin`,
  callRoom: `${RoutePrefix.TRIAL_PROS}/call-room`,
  claimTrialCompletion: `${RoutePrefix.TRIAL_PROS}/claim-trial/completion`,
  claimTrialSelectLocation: `${RoutePrefix.TRIAL_PROS}/claim-trial/select-location`,
  claimTrialSelectTrial: `${RoutePrefix.TRIAL_PROS}/claim-trial/select-trial`,
  feasibilityEstimate: `${RoutePrefix.TRIAL_PROS}/feasibility-estimate`,
  invite: (token: string) => `${RoutePrefix.TRIAL_PROS}/invite/${token}`,
  inviteInvalid: `${RoutePrefix.TRIAL_PROS}/invite/invalid`,
  matches: `${RoutePrefix.TRIAL_PROS}/matches`,
  matchesDocument: (applyIdToken: string, documentId: string) =>
    `${RoutePrefix.TRIAL_PROS}/matches/${applyIdToken}/documents/${documentId}`,
  matchesDocumentDownload: (applyIdToken: string, documentId: string) =>
    `${RoutePrefix.TRIAL_PROS}/matches/${applyIdToken}/documents/${documentId}/download`,
  matchesProfile: (idToken: string) =>
    `${RoutePrefix.TRIAL_PROS}/matches/${idToken}`,
  matchesUnverified: `${RoutePrefix.TRIAL_PROS}/matches/unverified`,
  onboarding: (nctId: string) =>
    `${RoutePrefix.TRIAL_PROS}/onboarding/${nctId}`,
  onboardingCompletion: `${RoutePrefix.TRIAL_PROS}/onboarding/completion`,
  onboardingSelectTrial: `${RoutePrefix.TRIAL_PROS}/onboarding/select-trial`,
  onboardingWelcome: `${RoutePrefix.TRIAL_PROS}/onboarding/welcome`,
  patientDossier: (idToken: string) =>
    `${RoutePrefix.TRIAL_PROS}/patient-handoff/${idToken}`,
  preScreenerSettings: `${RoutePrefix.TRIAL_PROS}/pre-screener`,
  redirect: `${RoutePrefix.TRIAL_PROS}/redirect`,
  registry: `${RoutePrefix.TRIAL_PROS}/registry`,
  rewards: `${RoutePrefix.TRIAL_PROS}/rewards`,
  rewardsSurvey: (surveyType: SurveyType) =>
    `${RoutePrefix.TRIAL_PROS}/rewards/${surveyType}`,
  siteLocations: `${RoutePrefix.TRIAL_PROS}/site-locations`,
  siteSettings: `${RoutePrefix.TRIAL_PROS}/site-settings`,
  trialSettings: `${RoutePrefix.TRIAL_PROS}/trial-settings`,
}

export default TrialProRoutes
