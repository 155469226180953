import Button from '@components/primitives/Button'
import Link from '@components/primitives/Link'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import { faGlasses } from '@fortawesome/pro-regular-svg-icons/faGlasses'
import TrialProRoutes from '@lib/routes/TrialProRoutes'
import { trackEvent } from '@lib/tracking'
import { TrackingEvents } from '@lib/tracking/TrackingEvents'
import { TrackingSources } from '@lib/tracking/TrackingSources'

export default function SiteStaffPortalButton({
  compact = false,
}: {
  compact?: boolean
}) {
  function trackSSPButtonClick() {
    trackEvent(
      TrackingEvents.SIGNED_IN_PATIENT_WEBSITE_SITE_STAFF_PORTAL_BUTTON_CLICKED,
      {
        source: TrackingSources.NAVBAR,
      },
    )
  }

  return (
    <Link
      className='mr-2 md:mx-2 xl:mx-4'
      href={TrialProRoutes.redirect}
      onClick={trackSSPButtonClick}
    >
      <Button.Icon
        className='mr-2 h-12 rounded-full md:h-[60px]'
        color='black'
        icon={faGlasses}
        iconClassName='md:mx-2 xl:mx-3 text-white'
      >
        <Text
          className={classMerge(
            'hidden whitespace-nowrap text-white',
            !compact && 'md:mr-2 md:block xl:mr-4',
          )}
          styleName='p-small'
          value='Site Staff Portal'
        />
      </Button.Icon>
    </Link>
  )
}
