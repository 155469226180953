import { useSignedInUser } from '@components/hooks/useSignedInUser'
import Button from '@components/primitives/Button'
import Link from '@components/primitives/Link'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import { faGlasses } from '@fortawesome/pro-regular-svg-icons/faGlasses'
import Routes from '@lib/routes'
import urlWithQueryParams from '@lib/routes/helpers/urlWithQueryParams'
import { trackEvent } from '@lib/tracking'
import { TrackingEvents } from '@lib/tracking/TrackingEvents'
import { TrackingSources } from '@lib/tracking/TrackingSources'
import { RoleQueryParam } from '@lib/types/RoleQueryParam'

export default function BecomeTrialProButton({
  compact = false,
}: {
  compact?: boolean
}) {
  const signedInUser = useSignedInUser()
  if (!signedInUser) {
    return null
  }

  function trackTrialProButtonClick() {
    trackEvent(
      TrackingEvents.SIGNED_IN_PATIENT_WEBSITE_BECOME_TRIAL_PRO_BUTTON_CLICKED,
      {
        source: TrackingSources.NAVBAR,
      },
    )
  }

  return (
    <Link
      className='mr-2 md:mx-4'
      href={urlWithQueryParams(Routes.signUp(RoleQueryParam.Researcher), {
        email: signedInUser.email,
      })}
      onClick={trackTrialProButtonClick}
    >
      <Button.Icon
        className='mr-2 h-12 rounded-full md:h-[60px]'
        color='black'
        icon={faGlasses}
        iconClassName='md:ml-3 md:mr-3 text-white'
      >
        <Text
          className={classMerge(
            'hidden text-white',
            !compact && 'md:mr-4 md:block',
          )}
          styleName='p-small'
          value='Site / Sponsor Account'
        />
      </Button.Icon>
    </Link>
  )
}
